import './SuccessPay.scss';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Nav from 'components/Nav/Nav';
import Lottie from 'lottie-react';
import TagManager from 'react-gtm-module';
import json from '../../assets/json/payment_success.json';
import { ONEMONTH, ONEYEAR, SIXMONTH } from 'config';

function SuccessPay(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedPrice = location.state?.selectedPrice;
  const handleDownload = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
      // iOS 링크
      window.open(
        'https://apps.apple.com/kr/app/%EA%B8%80%EB%A3%A8%EC%BD%94%ED%95%8F/id6446891488',
      );
    } else if (/android/.test(userAgent)) {
      // 안드로이드 링크
      window.open(
        'https://play.google.com/store/apps/details?id=com.lansik.glucofit',
      );
    }
  };

  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };

  useEffect(() => {
    const _nasa = {};
    TagManager.initialize(tagManagerArgs);
    const storedFinalAmountNum = localStorage.getItem('finalAmountNum');

    if (localStorage.getItem('selectedPrice') === ONEMONTH) {
      window.fbq('track', 'Purchase', { value: 276000, currency: 'KRW' });
    } else if (localStorage.getItem('selectedPrice') === ONEYEAR) {
      window.fbq('track', 'Purchase', {
        value:
          Math.round(
            parseInt(storedFinalAmountNum.replace(/,/g, '') / 100) / 12,
          ) * 100,
        currency: 'KRW',
      });
    } else if (localStorage.getItem('selectedPrice') === SIXMONTH) {
      window.fbq('track', 'Purchase', {
        value:
          Math.round(
            parseInt(storedFinalAmountNum.replace(/,/g, '') / 100) / 6,
          ) * 100,
        currency: 'KRW',
      });
    }

    // 네이버 전환 스크립트
    if (localStorage.getItem('selectedPrice') === ONEMONTH) {
      window.fbq('track', 'Purchase', { value: 276000, currency: 'KRW' });
      _nasa.cnv = window.wcs.cnv('1', '276000');
      window.wcs.inflow('glucofit.co.kr');
      window.wcs_do(_nasa);
    } else if (localStorage.getItem('selectedPrice') === ONEYEAR) {
      window.fbq('track', 'Purchase', {
        value:
          Math.round(
            parseInt(storedFinalAmountNum.replace(/,/g, '') / 100) / 12,
          ) * 100,
        currency: 'KRW',
      });
      _nasa.cnv = window.wcs.cnv('1', storedFinalAmountNum);
      window.wcs.inflow('glucofit.co.kr');
      window.wcs_do(_nasa);
    } else if (localStorage.getItem('selectedPrice') === SIXMONTH) {
      window.fbq('track', 'Purchase', {
        value:
          Math.round(
            parseInt(storedFinalAmountNum.replace(/,/g, '') / 100) / 6,
          ) * 100,
        currency: 'KRW',
      });
      _nasa.cnv = window.wcs.cnv('1', storedFinalAmountNum);
      window.wcs.inflow('glucofit.co.kr');
      window.wcs_do(_nasa);
    }

    //카카오 전환 스크립트
    if (typeof kakaoPixel !== 'undefined') {
      window.kakaoPixel('4055459468291690419').pageView();
      if (localStorage.getItem('selectedPrice') === ONEMONTH) {
        window.kakaoPixel('4055459468291690419').purchase({
          total_price: '276000',
          currency: 'KRW',
        });
      } else if (localStorage.getItem('selectedPrice') === ONEYEAR) {
        window.kakaoPixel('4055459468291690419').purchase({
          total_price:
            Math.round(
              parseInt(storedFinalAmountNum.replace(/,/g, '') / 100) / 12,
            ) * 100,
          currency: 'KRW',
        });
      } else if (localStorage.getItem('selectedPrice') === SIXMONTH) {
        window.kakaoPixel('4055459468291690419').purchase({
          total_price: storedFinalAmountNum,
          currency: 'KRW',
        });
      }
    }

    localStorage.clear();

    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.src = '//wcs.naver.net/wcslog.js';
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.innerHTML =
      'var _nasa={}; if(window.wcs) _nasa["cnv"] = wcs.cnv("1","10");';
    document.head.appendChild(script2);
  }, []);

  return (
    <div>
      <Nav />
      <div className="container-success">
        <div>
          <div className="lottie-image">
            <Lottie animationData={json} />
          </div>
          <div className="text-title-success">결제가 완료되었어요</div>
          <div className="text-subtitle-success">
            제품은 영업일 기준 2~3일 이내에 <br /> 수령하실 수 있어요.
          </div>
        </div>
        <div className="button-container-success">
          <button
            className="success-contact"
            onClick={() =>
              window.open('https://glucofit.channel.io/workflows/685226')
            }
          >
            글루코핏 고객센터
          </button>
          {/* <button className='success-toHome' onClick={handleNavigate}>
            앱 다운로드 하러 가기
          </button> */}
          <button className="success-toHome" onClick={handleDownload}>
            앱 다운로드 하러 가기
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessPay;
