import ArrowDown from '../../../assets/images/img_arrowDown.png';
import ArrowUp from '../../../assets/images/img_arrowUp.png';
import BenefitImage from '../../../assets/images/ver3.0/img-payment-benefit.png';
import MonthBadge from '../../../assets/images/ver3.0/img-payment-monthly-badge.png';
import YearBadge from '../../../assets/images/ver3.0/img-payment-yearly-badge.png';
import CgmImage from '../../../assets/images/ver3.0/img-sensor-count.png';
import '../CSS/MembershipBenefit.scss';

import { useState } from 'react';

import { useMobile } from 'hooks/common/useMobile';
import { useProduct } from 'hooks/product/useProduct';
import Dot from '../../../assets/icons/ic-dot.svg';
import Plus from '../../../assets/icons/sensor-count-plus.svg';

export default function MembershipBenefit({
  type,
  membershipName,
  sensorCount,
  eventSensorCount,
  deliveryMessage,
}) {
  const [isBenefitExpanded, setIsBenefitExpanded] = useState(true);
  const { isMobile } = useMobile();
  const { PRODUCT_TYPE } = useProduct();
  return (
    <div className="membership-payment-top">
      <div className="membership-product-benefit-container">
        <div className="membership-product-img-container">
          <img
            src={BenefitImage}
            alt=""
            className="membership-product-benfit-img"
            id={`${
              type === PRODUCT_TYPE.MONTH_MEMBERSHIP
                ? 'benefit-one-month'
                : 'benefit-one-year'
            }`}
          />
          <img
            src={
              type === PRODUCT_TYPE.MONTH_MEMBERSHIP ? MonthBadge : YearBadge
            }
            alt=""
            className="membership-product-badge-img"
          />
        </div>
        <div className="membership-product-benefit">
          <div className="benefit-title">
            <div className="benefit-type strong">
              {type === PRODUCT_TYPE.MONTH_MEMBERSHIP
                ? '1개월 멤버십'
                : '1년 멤버십'}
            </div>
          </div>
          <div className="benefit-list">
            <div
              className="benefit-toggle"
              onClick={() => setIsBenefitExpanded((prev) => !prev)}
            >
              <div className="benefit-toggle-button mobile">
                혜택
                <img
                  className="membership-arrow"
                  src={isBenefitExpanded ? ArrowDown : ArrowUp}
                  alt="Arrow"
                />
              </div>
            </div>
            {(isBenefitExpanded || !isMobile) && (
              <div
                className={`benefit-element ${isBenefitExpanded && 'expended'}`}
              >
                <li>
                  <span className="strong">AI 혈당 분석</span> 글루코핏 어플
                  사용권
                </li>
                <li>
                  <span className="strong">의사 코치 무제한</span> 1:1 채팅 질의
                  응답
                </li>
                <li>
                  <span className="strong">온라인 혈당</span> 강의 & 컨텐츠 제공
                </li>
                <li>
                  센서 오류시
                  <span className="strong"> 무상 무제한 교환</span>
                </li>
                {type === PRODUCT_TYPE.YEAR_MEMBERSHIP && (
                  <>
                    <li>
                      연속혈당측정기&nbsp;
                      <span className="strong">국내 최저가</span> 구매
                    </li>
                    <li>
                      각종 건강 식품 및 커뮤니티 활동 <br className="mobile" />
                      <span className="strong">할인 제공</span>
                    </li>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="membership-plus">
        <img src={Plus} alt="" className="plus" />
      </div>
      <div className="membership-product-cgm-container">
        <div className="membership-product-cgm-img-container">
          <img src={CgmImage} alt="" className="membership-product-cgm-img" />
        </div>
        <div className="membership-product-cgm">
          <div className="membership-product-cgm-title-container">
            <div className="membership-product-cgm-title">
              {membershipName}
              <div className="strong">
                연속혈당측정기 {sensorCount}개
                {eventSensorCount > 0 && (
                  <span className="cgm-event">
                    + 이벤트 {eventSensorCount}개 더!
                  </span>
                )}
              </div>
            </div>
            <div className="membership-product-cgm-price strong" />
          </div>
          <div className="membership-product-cgm-subtitle">
            팔에 붙이기만 하면 <br />
            혈당을 측정해주는 스마트한
            <span className="strong orange"> 연속혈당측정기(CGM)</span>
          </div>
          <div className="membership-sensor-container">
            <div className="membership-sensor-title strong">
              <span className="color">센서는 개당 2주 사용가능</span>
              합니다.
            </div>
            <span className="membership-sensor-description">
              <img src={Dot} alt="" className="dot" />
              <div>
                <span className="strong">
                  글루코핏 앱 만을 통해 센서가 없을 때도
                </span>
                <br />
                AI혈당 예측값을 볼 수 있어요
              </div>
            </span>
            {type === PRODUCT_TYPE.YEAR_MEMBERSHIP && (
              <div className="membership-sensor">
                <div className="tag">배송 정보</div>
                <div>{deliveryMessage}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
