import Footer from 'components/Footer/Footer';
import Nav from 'components/Nav/Nav';
import ReviewSwiper from 'components/Review/ReviewSwiper';
import { useProduct } from 'hooks/product/useProduct';
import MyDreamMembershipSelector from 'pages/MyDream/Components/MyDreamMembershipSelector';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/MyDreamInfo.scss';

export default function MyDreamInfo() {
  const ref = useRef();
  const navigate = useNavigate();

  const { PRODUCT_TYPE, products, selectedProduct, setSelectedProductById } =
    useProduct();

  const onNavigate = () => {
    navigate(`/mydream/payment?product_id=${selectedProduct.id}`);
  };

  useLayoutEffect(() => {
    setSelectedProductById(38); // 마이드림 2개 4주 멤버십 default
  }, []);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);

  return (
    <div ref={ref}>
      <Nav />
      <MyDreamMembershipSelector
        onNavigate={onNavigate}
        PRODUCT_TYPE={PRODUCT_TYPE}
        products={products}
        selectedProduct={selectedProduct}
        setSelectedProductById={setSelectedProductById}
      />
      <div className="membership-review">
        <div className="membership-review-title strong">리뷰 / 후기</div>
        <ReviewSwiper buttonColor="#3D3D3D" />
      </div>
      <div className="membership-payment-button-container">
        <button
          className="membership-payment-button strong"
          onClick={onNavigate}
        >
          멤버십 시작하기
        </button>
      </div>
      <Footer />
    </div>
  );
}
