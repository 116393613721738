import SelectPrice from 'pages/Modal/SelectPrice';
import { useEffect, useRef, useState } from 'react';
import '../CSS/Header.scss';
function Header({ initCheckout, setIsIntersecting }) {
  const targetRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  // const handleClick = () => {
  //   if (window.location.href.includes('drdinho')) {
  //     navigate('/invite2?code=drdinho');
  //   } else {
  //     navigate('/info');
  //   }
  //   window.gtag('event', 'conversion', {
  //     send_to: 'AW-10992256925/KpgjCL7zqKwYEJ2Pwvko',
  //   });
  //   window.fbq('track', 'InitiateCheckout');
  //   // setShowModal(true);
  // };

  const handleClose = () => {
    setShowModal(false);
  };

  const callback = (entries) => {
    entries.forEach((entry) => {
      setIsIntersecting(!!entry.isIntersecting);
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, { threshold: 0 });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div className="container1">
        <video
          muted
          autoPlay
          loop
          playsInline
          style={{ width: '100%' }}
          className="image-container"
        >
          <source
            src="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/1024_%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5+%E1%84%86%E1%85%AE%E1%84%83%E1%85%B3+%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC_1.mp4"
            type="video/mp4"
          />
        </video>
        <video
          muted
          autoPlay
          loop
          playsInline
          style={{ width: '100%' }}
          className="image-container-mobile"
        >
          <source
            src="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/1024_%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5+%E1%84%86%E1%85%AE%E1%84%83%E1%85%B3+%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC_7.mp4"
            type="video/mp4"
          />
        </video>
        <div className="text-container" ref={targetRef}>
          <h1 className="text-title36 strong">
            의사가 만든 <br /> 국내 최초 혈당 케어
          </h1>
          <div className="text-subtitle18">
            어떤 음식을 먹으면 혈당 스파이크가 발생하는지 확인하고 <br /> 더
            똑똑하고 건강하게 혈당을 관리해보세요
          </div>
          <button className="start-button" onClick={initCheckout}>
            지금 시작하기
          </button>
          {showModal && (
            <SelectPrice show={showModal} handleClose={handleClose} />
          )}
        </div>
      </div>
    </div>
  );
  // return (
  //   <div>
  //     <div className='container1'>
  //       <video muted autoPlay loop playsInline className='video-container'>
  //         <source
  //           src='https://glucofit-image.s3.ap-northeast-2.amazonaws.com/1024_%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5+%E1%84%86%E1%85%AE%E1%84%83%E1%85%B3+%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC_1.mp4'
  //           type='video/mp4'
  //         />
  //       </video>
  //       <div className='header-container'>
  //         <h1 className='header-logo strong'>Glucofit</h1>
  //         <h2 className='header-title strong'>
  //           국내 최초 의사가 만든 <br /> 혈당 다이어트
  //         </h2>
  //         <div className='header-description web'>
  //           어떤 음식을 먹으면 살이 찌는지 데이터로 확인하고 <br />더 똑똑하고 건강하게 체지방을
  //           감량해보세요
  //         </div>
  //         <div className='header-description mobile'>의사와 서울대가 만든 No.1 혈당 다이어트</div>
  //         <button className='start-button strong mobile' onClick={initCheckout}>
  //           지금 시작하기
  //         </button>
  //         {showModal && <SelectPrice show={showModal} handleClose={handleClose} />}
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Header;
