import '../CSS/MyDreamSideBar.scss';

export default function MyDreamSideBar({
  children,
  price,
  discount,
  isButtonDisabled,
  handleButtonClicked,
}) {
  return (
    <div className="side-bar-contents">
      <div className="payment-event-container">
        <div className="payment-event-top">
          <div className="payment-event-title strong">멤버십 결제하기</div>
          <div className="payment-event-subtitle">
            [EVENT] 12개월 무이자 할부 지원
          </div>
        </div>
      </div>
      <div className="payment-total-container">
        <div className="payment-total-info">
          <div className="payment-total-line">
            <div>총 상품 금액</div>
            <div>{price.toLocaleString()}원</div>
          </div>
          <div className="payment-total-line">
            {discount ? (
              <>
                <div>
                  할인
                  <div className="sale-tag">
                    {Math.ceil((discount / price) * 100)}% 할인
                  </div>
                </div>
                <div className="orange">-{discount.toLocaleString()}원</div>
              </>
            ) : (
              <>
                <div>할인</div>
                <div>0원</div>
              </>
            )}
          </div>
        </div>
        <div className="payment-total-price-container strong">
          <div>최종 결제 금액</div>
          <div className="payment-price">
            {(price - discount).toLocaleString()}원
          </div>
        </div>
      </div>
      {children}
      <button
        className={`payment-total-button ${
          !isButtonDisabled && 'checked'
        } strong`}
        onClick={handleButtonClicked}
      >
        {(price - discount).toLocaleString()}원 결제하기
      </button>
    </div>
  );
}
