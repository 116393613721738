import Footer from 'components/Footer/Footer';
import Nav from 'components/Nav/Nav';
import About from 'pages/Main/Components/About';
import Aimode from 'pages/Main/Components/Aimode';
import BlogReview from 'pages/Main/Components/BlogReview';
import FaQ from 'pages/Main/Components/FaQ';
import Header from 'pages/Main/Components/Header';
import Intro from 'pages/Main/Components/Intro';
import Introduction from 'pages/Main/Components/Introduction';
import Journey from 'pages/Main/Components/Journey';
import KakaoChannel from 'pages/Main/Components/KakaoChannel';
import PositiveEffect from 'pages/Main/Components/PositiveEffect';
import Profile from 'pages/Main/Components/Profile';
import Review from 'pages/Main/Components/Review';
import SpecialEvent from 'pages/Main/Components/SpecialEvent';
import Steps from 'pages/Main/Components/Steps';
import Youtube from 'pages/Main/Components/Youtube';
import 'pages/Main/Main.scss';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';

const Main = ({ CustomSpecialComponent, customInitCheckout }) => {
  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    TagManager.initialize(tagManagerArgs);
  }, []);

  // 제휴 대응
  const [referralUrl, setReferralUrl] = useState(null);
  const [referralInvitePath, setReferralInvitePath] = useState(null);
  useEffect(() => {
    const afterProtocol = window.location.href.split('://')[1];
    const parts = afterProtocol.split('/');
    const subdomain = parts[1];

    setReferralUrl(subdomain);
    console.log('subdomain...', subdomain);

    if (subdomain === 'drdinho') {
      setReferralInvitePath('/info?coupon_number=drdinho');
    } else if (subdomain === 'drlee') {
      setReferralInvitePath('/info?coupon_number=drlee');
    } else if (subdomain === 'hwalmyeong') {
      setReferralInvitePath('/info?coupon_number=hwalmyeong');
    } else if (subdomain === 'hafeteria') {
      setReferralInvitePath('/info?coupon_number=hafeteria');
    } else if (subdomain === 'salb') {
      setReferralInvitePath('/info?coupon_number=salb');
    } else if (subdomain === 'lare') {
      setReferralInvitePath('/info?coupon_number=lare');
    } else if (subdomain === 'reviewmachine') {
      setReferralInvitePath('/info?coupon_number=reviewmachine');
    } else if (subdomain === 'doctorleetv') {
      setReferralInvitePath('/info?coupon_number=doctorleetv');
    } else if (subdomain === 'mktv') {
      setReferralInvitePath('/info?coupon_number=mktv');
    } else if (subdomain === 'coupon0801') {
      setReferralInvitePath('/info?coupon_number=coupon0801');
    }

    // <Route path="/reviewmachine" element={<Main />} />
    // <Route path="/dotorleetv" element={<Main />} />
  }, []);

  // 지금 시작하기 활성화, 비활성화
  const [isIntersecting, setIsIntersecting] = useState(false);

  //접근
  const initCheckout = () => {
    if (customInitCheckout) {
      customInitCheckout();
      return;
    }

    if (
      [
        'drdinho',
        'drlee',
        'hwalmyeong',
        'hafeteria',
        'salb',
        'lare',
        'reviewmachine',
        'doctorleetv',
        'mktv',
        'coupon0801',
      ].includes(referralUrl)
    ) {
      navigate(referralInvitePath);
    } else if (
      localStorage.getItem('inviteCode') != 'null' &&
      localStorage.getItem('inviteCode') != null
    ) {
      navigate(`/info?code=${localStorage.getItem('inviteCode')}`);
    } else {
      navigate('/info');
    }
    localStorage.clear();
    window.gtag('event', 'conversion', {
      send_to: 'AW-10992256925/KpgjCL7zqKwYEJ2Pwvko',
    });
    window.fbq('track', 'InitiateCheckout');
  };

  return (
    <div className="wrapper">
      <div style={{ position: 'relative' }}>
        <Nav initCheckout={initCheckout} isIntersecting={isIntersecting} />
        <Header
          initCheckout={initCheckout}
          setIsIntersecting={setIsIntersecting}
        />
        <Intro />
        <Review />
        <About />
        <Introduction />
        {CustomSpecialComponent ? (
          <CustomSpecialComponent />
        ) : (
          <SpecialEvent initCheckout={initCheckout} />
        )}
        <Profile />
        <Youtube />
        <Steps />
        <Journey />
        <BlogReview />
        <Aimode />
        <PositiveEffect />
        <FaQ />
        <Footer />
        <KakaoChannel />
        {!isIntersecting && (
          <button className="strong" id="start-button" onClick={initCheckout}>
            지금 시작하기
          </button>
        )}
      </div>
    </div>
  );
};

export default Main;
