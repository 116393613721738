import AnyfitAgreement from 'pages/AnyfitMall/anyfitAgreement.jsx';
import AnyfitErrorPage from 'pages/AnyfitMall/anyfitErrorPage.jsx';
import AnyfitFAQ from 'pages/AnyfitMall/anyfitFAQ.jsx';
import AnyfitOrderList from 'pages/AnyfitMall/anyfitOrderList.jsx';
import AnyfitProductDetail from 'pages/AnyfitMall/anyfitProductDetail.jsx';
import AnyfitProductDetailV2 from 'pages/AnyfitMall/anyfitProductDetailV2.jsx';
import AnyfitProductList from 'pages/AnyfitMall/anyfitProductList.jsx';
import AnyfitQuestionAdd from 'pages/AnyfitMall/anyfitQuestionAdd.jsx';
import AnyfitQuestionList from 'pages/AnyfitMall/anyfitQuestionList.jsx';
import CertificationComponent from 'pages/CiAuth/CertificationComponent.jsx';
import ErrorPage from 'pages/InviteFriends/ErrorPage.jsx';
import Reward2WeeksPage from 'pages/InviteFriends/Reward2WeeksPage.jsx';
import RewardCgm202310Page from 'pages/InviteFriends/RewardCgm202310Page.jsx';
import RewardCgmEventPage from 'pages/InviteFriends/RewardCgmEventPage.jsx';
import RewardCgmPage from 'pages/InviteFriends/RewardCgmPage.jsx';
import Index from 'pages/Main/Components/Index.jsx';
import GlucofitMallDetail from 'pages/Mall/GlucofitMallDetail.jsx';
import GlucofitMallPage from 'pages/Mall/GlucofitMallPage.jsx';
import PostCodeMall from 'pages/Mall/PostCodeMall.jsx';
import RedirectMall from 'pages/Mall/RedirectMall.jsx';
import SuccessMall from 'pages/Mall/SuccessMall.jsx';
import MembershipInfo from 'pages/MembershipInfo/MembershipInfo.jsx';
import MembershipOnlyPage from 'pages/MembershipOnly/MembershipOnlyPage.jsx';
import PostCodeMembership from 'pages/MembershipOnly/PostCodeMembership.jsx';
import MyDreamInfo from 'pages/MyDream/Components/MyDreamInfo.jsx';
import MyDreamPayment from 'pages/MyDream/Components/MyDreamPayment.jsx';
import MyDream from 'pages/MyDream/MyDream.jsx';
import PaymentMembership from 'pages/Payment/PaymentMembership.jsx';
import PaymentMethodChange from 'pages/Payment/PaymentMethodChange.jsx';
import PostCodeEvent from 'pages/Payment/PostCodeEvent.jsx';
import PostCodeReward2Weeks from 'pages/Payment/PostCodeReward2Weeks.jsx';
import PostCodeRewardCgm from 'pages/Payment/PostCodeRewardCgm.jsx';
import RedirectSubscription from 'pages/Payment/RedirectSubscription.jsx';
import RaffleMain from 'pages/Raffle/raffleMain.jsx';
import RaffleScratch from 'pages/Raffle/raffleScratch.jsx';
import RaffleSms from 'pages/Raffle/raffleSms.jsx';
import PostCodeSensor from 'pages/SensorOnly/PostCodeSensor.jsx';
import RedirectSensorOnly from 'pages/SensorOnly/RedirectSensorOnly.jsx';
import SensorOnlyPage from 'pages/SensorOnly/SensorOnlyPage.jsx';
import SuccessFreeReward from 'pages/SuccessPay/SuccessFreeReward.jsx';
import TwoWeeksPage from 'pages/TwoWeeks/TwoWeeksPage.jsx';
import Welstory from 'pages/WelStory/Welstory.jsx';
import WelstoryPayment from 'pages/WelStory/WelstoryPayment.jsx';
import ReactGA from 'react-ga4';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Blog from './pages/Blog/Blog.jsx';
import Main from './pages/Main/Main.jsx';
import PostCode from './pages/Payment/PostCode.jsx';
import Redirect from './pages/Payment/Redirect.jsx';
import ReviewPage from './pages/RealReview/ReviewPage';
import SuccessPay from './pages/SuccessPay/SuccessPay.jsx';

const gaTrackingId = 'G-QGTMVZN64F'; // 환경 변수에 저장된 추적ID 가져오기
ReactGA.initialize(gaTrackingId);

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/index.html" element={<Index />} />
        <Route path="/redirect" element={<Redirect />} />
        <Route path="/redirectmall" element={<RedirectMall />} />
        <Route path="/redirectsensoronly" element={<RedirectSensorOnly />} />
        <Route
          path="/redirectsubscription"
          element={<RedirectSubscription />}
        />
        <Route path="/successpay" element={<SuccessPay />} />
        <Route path="/successmall" element={<SuccessMall />} />
        <Route path="/successreward" element={<SuccessFreeReward />} />
        <Route path="/postcode" element={<PostCode />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/postcodeevent" element={<PostCodeEvent />} />
        <Route path="/postcodereward" element={<PostCodeReward2Weeks />} />
        <Route path="/postcodecgm" element={<PostCodeRewardCgm />} />
        <Route path="/postcodesensor" element={<PostCodeSensor />} />
        <Route path="/postcodeMembership" element={<PostCodeMembership />} />
        <Route path="/reward2weeks" element={<Reward2WeeksPage />} />
        <Route path="/rewardcgm" element={<RewardCgmPage />} />
        <Route path="/rewardcgmevent" element={<RewardCgmEventPage />} />
        <Route path="/rewardcgm202310" element={<RewardCgm202310Page />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/sensoronly" element={<SensorOnlyPage />} />
        <Route path="/membershipOnly" element={<MembershipOnlyPage />} />
        <Route path="/2weeks" element={<TwoWeeksPage />} />
        <Route path="/review" element={<ReviewPage />} />

        {/* CI 인증 */}
        <Route path="ciauth" element={<CertificationComponent />} />

        {/* 초대 */}
        <Route path="/invite" element={<MembershipInfo />} />

        {/* 몰 */}
        <Route path="/mall" element={<GlucofitMallPage />} />
        <Route path="/mall/detail/:itemId" element={<GlucofitMallDetail />} />
        <Route path="/postcodemall/:itemId" element={<PostCodeMall />} />

        {/* 결제 & 상품 관련 */}
        <Route path="/payment" element={<PaymentMembership />} />
        <Route path="/info" element={<MembershipInfo />} />
        <Route
          path="/payment/method-change"
          element={<PaymentMethodChange />}
        />

        {/* 제휴 관련 */}
        <Route path="/drdinho" element={<Main />} />
        <Route path="/drlee" element={<Main />} />
        <Route path="/hwalmyeong" element={<Main />} />
        <Route path="/salb" element={<Main />} />
        <Route path="/lare" element={<Main />} />
        <Route path="/hafeteria" element={<Main />} />
        <Route path="/reviewmachine" element={<Main />} />
        <Route path="/doctorleetv" element={<Main />} />
        <Route path="/mktv" element={<Main />} />
        <Route path="/coupon0801" element={<Main />} />

        {/* 래플 */}
        <Route path="/raffle" element={<RaffleMain />} />
        <Route path="/raffle/sms" element={<RaffleSms />} />
        <Route path="/raffle/scratch" element={<RaffleScratch />} />

        {/* 애니핏 몰 */}
        <Route path="/anyfit/productList" element={<AnyfitProductList />} />
        <Route
          path="/anyfit/productDetail/:productId"
          element={<AnyfitProductDetail />}
        />
        <Route
          path="/anyfit/productDetailV2/"
          element={<AnyfitProductDetailV2 />}
        />
        <Route path="/anyfit/questionList" element={<AnyfitQuestionList />} />
        <Route path="/anyfit/question/add" element={<AnyfitQuestionAdd />} />
        <Route path="/anyfit/orderList" element={<AnyfitOrderList />} />
        <Route path="/anyfit/agreement" element={<AnyfitAgreement />} />
        <Route path="/anyfit/faq" element={<AnyfitFAQ />} />
        <Route path="/anyfit/error" element={<AnyfitErrorPage />} />

        {/* 웰 스토리 */}
        <Route path="/welstory" element={<Welstory />} />
        <Route path="/welstory/payment" element={<WelstoryPayment />} />

        {/* 에브리핏 */}
        <Route path="/everyfit/payment" element={<WelstoryPayment />} />

        {/* 마이드림 */}
        <Route path="/mydream" element={<MyDream />} />
        <Route path="/mydream/info" element={<MyDreamInfo />} />
        <Route path="/mydream/payment" element={<MyDreamPayment />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
