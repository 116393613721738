import '../SuccessPay/SuccessPay.scss';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Nav from 'components/Nav/Nav';
import Lottie from 'lottie-react';
import TagManager from 'react-gtm-module';
import json from '../../assets/json/payment_success.json';

function SuccessMall(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedPrice = location.state?.selectedPrice;
  const handleDownload = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
      // iOS 링크
      window.open(
        'https://apps.apple.com/kr/app/%EA%B8%80%EB%A3%A8%EC%BD%94%ED%95%8F/id6446891488',
      );
    } else if (/android/.test(userAgent)) {
      // 안드로이드 링크
      window.open(
        'https://play.google.com/store/apps/details?id=com.lansik.glucofit',
      );
    }
  };

  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };

  useEffect(() => {
    const _nasa = {};
    TagManager.initialize(tagManagerArgs);
    localStorage.clear();
  }, []);

  return (
    <div>
      <Nav />
      <div className="container-success">
        <div>
          <div className="lottie-image">
            <Lottie animationData={json} />
          </div>
          <div className="text-title-success">결제가 완료되었어요</div>
          <div className="text-subtitle-success">
            제품은 영업일 기준 2~3일 이내에 <br /> 수령하실 수 있어요.
          </div>
        </div>
        <div className="button-container-success">
          <button
            className="success-contact-mall"
            onClick={() =>
              window.open('https://glucofit.channel.io/workflows/685226')
            }
          >
            글루코핏 스토어 고객센터
          </button>
          {/* <button className='success-toHome' onClick={handleNavigate}>
            앱 다운로드 하러 가기
          </button> */}
          {/* <button className='success-toHome' onClick={handleDownload}>
            앱 다운로드 하러 가기
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default SuccessMall;
