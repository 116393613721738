import React from 'react';
import './Footer.scss';
import { logEvent } from 'pages/Main/Components/Amplitude';

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="info">
          <div className="footer-title strong">정보</div>
          <a
            className="footer-contents"
            href="https://blog.glucofit.co.kr/"
            target="_blank"
            rel="noopener noreferrer"
            // onClick={logEvent('glucoselab_click', 10201)}
          >
            글루코핏 혈당 연구소
          </a>
          <a
            className="footer-contents"
            href="https://glucofit.framer.website/review"
            target="_blank"
            rel="noopener noreferrer"
            // onClick={logEvent('glucofitreview_entered', 10102)}
          >
            블로그 후기
          </a>
        </div>
        <div className="contact">
          <div className="footer-title strong">문의</div>
          <div className="info">
            <a
              className="footer-contents"
              href="https://glucofit.channel.io/workflows/685226"
              target="_blank"
              rel="noopener noreferrer"
              // onClick={logEvent('cschannel_click', 10203)}
            >
              고객센터
            </a>
            <a
              className="footer-contents"
              href="https://lansik.notion.site/30e6fc8b10f84fddb72338f956e1f2c8"
              target="_blank"
              rel="noopener noreferrer"
            >
              이용약관
            </a>
            <a
              className="footer-contents"
              href="https://lansik.notion.site/8db26720c46a4650a6dd64bd0ee9d0d8?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              개인정보처리방침
            </a>
            <a
              className="footer-contents"
              target="_blank"
              rel="noopener noreferrer"
            >
              제휴 문의 : support@lansik.us
            </a>
          </div>
        </div>
        <div className="company">
          <div className="footer-title strong">회사정보</div>
          <div className="footer-contents">
            주식회사 랜식 <br />
            대표자: 양혁용 <br />
            서울특별시 서초구 사임당로8길 13, 제일빌딩 402-J281호 <br />
            사업자 등록 번호: 253-87-02471 <br />
            통신판매 신고번호 : 2024-서울서초-1164 <br />
            의료기기판매업 신고번호: 제2024-3210034-00150호 <br />
            연락처 : 1551-6061
            <br />
            {/* <span style={{ fontWeight: 'weight', fontSize: '0.8rem' }}>
              현재 유선 상담은 불가하며 <br />
              문의사항은 카카오톡채널 @글루코핏 에서 가능합니다.
            </span> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
