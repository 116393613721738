import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import json from '../../assets/json/payment_success.json';

function SuccessFreeReward(props) {
  const navigate = useNavigate();
  const handleHome = () => {
    navigate('/');
  };

  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div>
      {/* <Nav /> */}
      <div className="container-success">
        <div>
          <div className="lottie-image-reward">
            <Lottie animationData={json} />
          </div>
          <div className="text-title-success strong">결제가 완료 되었어요</div>
          <div className="text-subtitle-success">
            발송 안내를 카카오톡으로 보내드렸어요.
          </div>
          <div className="success-container">
            <div className="text-subtitle-success">
              제품은 영업일 기준 2~3일 이내에 <br /> 수령하실 수 있어요.
            </div>
          </div>
        </div>
        <div className="button-container-success">
          <button
            className="success-contact-reward"
            onClick={() =>
              window.open('https://glucofit.channel.io/workflows/685226')
            }
          >
            글루코핏 고객센터
          </button>
          {/* <button className='success-toHome' onClick={handleHome}>
            완료
          </button> */}
        </div>
        <div className="confirm-button-wrapper">
          <button
            className="confirm-button strong"
            onClick={() => navigate('/')}
          >
            확인했어요
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessFreeReward;
