import { useMembershipCarousel } from 'hooks/membsershipinfo/useMembershipCarousel';
import Carousel from 'pages/MembershipInfo/Components/Carousel';
import '../CSS/MyDreamMembershipSelector.scss';

export default function MyDreamMembershipSelector({
  PRODUCT_TYPE,
  products,
  selectedProduct,
  setSelectedProductById,
  onNavigate,
}) {
  const { setActiveCarousel, activeThemeColor } = useMembershipCarousel();

  const handleMembership = (id) => {
    setSelectedProductById(id);
  };

  if (!selectedProduct) return null;
  return (
    <div className="selector-container">
      <div className="title">
        가장 합리적인 <div className="strong">글루코핏 멤버십</div>
      </div>
      <div
        className="membership-content"
        style={{ backgroundColor: activeThemeColor }}
      >
        <div className="left-content">
          <Carousel setActive={setActiveCarousel} />
        </div>
        <div className="right-content">
          <div className="price">
            <span className="strong">
              개당{' '}
              {(
                (selectedProduct.price - selectedProduct.discount) /
                selectedProduct.sensorCount
              ).toLocaleString()}
              원
            </span>
          </div>
          <div className="text">
            건강 챙기는 분들을 위해 <br />
            <span className="strong">12개월 무이자 할부 이벤트</span>를
            시작합니다!
          </div>
          <div className="membership-products">
            {products
              .filter((m) => m.type === PRODUCT_TYPE.MYDREAM)
              .map((m) => (
                <div
                  key={m.id}
                  className={`product ${m.theme} ${
                    selectedProduct.id === m.id && 'selected'
                  }`}
                  onClick={() => handleMembership(m.id)}
                >
                  <div className="product-left">
                    <div className={`product-badge strong ${m.theme}`}>
                      {m.badge}
                    </div>
                    <div className="product-sensor strong">{m.badgeText}</div>
                  </div>
                  <div className="product-center">
                    {m.event && <div className="product-event">{m.event}</div>}
                    <div className="product-content">{m.content}</div>
                    <div className="product-description">{m.description}</div>
                  </div>
                  <div className="product-select-button" />
                </div>
              ))}
          </div>
          <button className="next-button" onClick={onNavigate}>
            다음
          </button>
        </div>
      </div>
    </div>
  );
}
