import Main from 'pages/Main/Main';
import SpecialEvent from 'pages/MyDream/Components/SpecialEvent';
import { useNavigate } from 'react-router-dom';

export default function MyDream() {
  const navigate = useNavigate();

  const initCheckout = () => {
    navigate('/mydream/info');
  };
  const CustomSpecialComponent = () => <SpecialEvent navigate={initCheckout} />;

  return (
    <Main
      CustomSpecialComponent={CustomSpecialComponent}
      customInitCheckout={initCheckout}
    />
  );
}
